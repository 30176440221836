import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '46, 56, 108',
		'primary-dark': '22, 33, 91',
		'accent': '255, 213, 158',
		'accent-plus': '37, 34, 23',
	},
});
